@use "sass:math";

$font-size-base: 18px;
$font-size-min: 14px;
$font-family: "Spoqa Han Sans Neo",-apple-system,BlinkMacSystemFont,system-ui,Roboto,"Helvetica Neue","Segoe UI","Apple SD Gothic Neo","Noto Sans KR","Malgun Gothic",sans-serif;

$color-primary: #1428A0;
$color-secondary1: #1C9C9E;
$color-secondary2: #1D7FBF;
$color-secondary3: #8E1FFF;
$color-secondary4: #FE9D34;
$color-secondary5: #56a311;


$color-white : #fff;
$color-black : #000;
$color-gray-c : #ccc;
$color-gray-8 : #888;
$color-gray-6 : #666;
$color-gray-2 : #222;

$border-color: #E5E5E5;

$breakpoint-desktop: 1280px;
$breakpoint-tablet: 1024px;
$breakpoint-mobile: 768px;
$breakpoint-min: 640px;

//추후 footer 순서, font-size 변경시 수치 조절해줘야함
$breakpoint-footer: 418px;

$content-box-max-width: 1920px;
$content-box-mobile-width: 375px;
$gutter: 80px;
$gutter-tablet: 40px;
$gutter-mobile: 20px;

$grid-gutter: 16px;
$grid-gutter-tablet: 10px;
$grid-gutter-mobile: 10px;
$header-z-index: 999;
$footer-z-index: 99;

$header-top-bar-height: 48px;
$header-top-bar-height-m: 48px;


$header-main-height: (160px - $header-top-bar-height);
$header-main-height-shrink: (124px - $header-top-bar-height);
$header-main-height-m: (112px - $header-top-bar-height-m);

@function __to_rem($size, $base) {
    @return  math.div($size, $base) * 1rem;
}
@function __to_em($size, $base) {
    @return  math.div($size, $base) * 1em;
}
@function __to_vw($size, $base) {
    @return  math.div($size, $base) * 100vw;
}
@function __to_vh($size, $base) {
    @return  math.div($size, $base) * 100vh;
}
@function __to_per($size, $base) {
    @return  math.div($size, $base) * 100%;
}
@mixin __txt_to_rem($size, $lh, $base) {
    font-size: __to_rem($size, $base);
    line-height: __to_em($lh, $size);
}

@mixin __txt_to_em($size, $lh, $base) {
    font-size: __to_em($size, $base);
    line-height: __to_em($lh, $size);
}


@mixin __wh_to_rem($width, $height, $base) {
    min-width: __to_rem($width, $base);
    width: __to_rem($width, $base);
    height: __to_rem($height, $base);
}
@mixin __wh_to_em($width, $height, $base) {
    min-width: __to_em($width, $base);
    width: __to_em($width, $base);
    height: __to_em($height, $base);
}
@mixin __wh_to_vw($width, $height, $base) {
    min-width: __to_vw($width, $base);
    width: __to_vw($width, $base);
    height: __to_vw($height, $base);
}
@mixin __wh_to_vh($width, $height, $base) {
    min-width: __to_vh($width, $base);
    width: __to_vh($width, $base);
    height: __to_vh($height, $base);
}

@mixin position_x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin position_y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin position_xy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

@mixin fullsize {
    position: absolute;
    width: 100%;
    height: 100%;
}
@mixin dimmed($r:0,$g:0,$b:0) {
    @include fullsize;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: linear-gradient(to bottom, rgba($r,$g,$b,0.0) 0%, rgba($r,$g,$b,.3) 50%, rgb($r,$g,$b,1) 100%);
    opacity: .6;
}


@mixin dimmed2($r:0,$g:0,$b:0) {
    @include fullsize;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: linear-gradient(to bottom, rgba($r,$g,$b,0) 0%, rgb($r,$g,$b,1) 100%);
    opacity: .8;
}


@mixin dimmed3($r:0,$g:0,$b:0) {
    @include fullsize;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: linear-gradient(to bottom, rgba($r,$g,$b,0.2) 0%, rgb($r,$g,$b,1) 100%);
    opacity: .7;
}

@mixin dimmed4($r:0,$g:0,$b:0) {
    @include fullsize;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: linear-gradient(to bottom, rgba($r,$g,$b,0.2) 0%, rgb($r,$g,$b,0.5) 100%);
    opacity: 1;
}

//easing
$linear         : cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease           : cubic-bezier(0.250, 0.100, 0.250, 1.000);
$easeIn        : cubic-bezier(0.420, 0.000, 1.000, 1.000);
$easeOut       : cubic-bezier(0.000, 0.000, 0.580, 1.000);
$easeInOut    : cubic-bezier(0.420, 0.000, 0.580, 1.000);

$easeInQuad     : cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic    : cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart    : cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint    : cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine     : cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo     : cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc     : cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack     : cubic-bezier(0.600, -0.280, 0.735, 0.045);

$easeOutQuad    : cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic   : cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart   : cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint   : cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine    : cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo    : cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc    : cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack    : cubic-bezier(0.175, 0.885, 0.320, 1.275);

$easeInOutQuad  : cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart : cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint : cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine  : cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo  : cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack  : cubic-bezier(0.680, -0.550, 0.265, 1.550);

//easing
$linear         : cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease           : cubic-bezier(0.250, 0.100, 0.250, 1.000);
$easeIn        : cubic-bezier(0.420, 0.000, 1.000, 1.000);
$easeOut       : cubic-bezier(0.000, 0.000, 0.580, 1.000);
$easeInOut    : cubic-bezier(0.420, 0.000, 0.580, 1.000);

$easeInQuad     : cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic    : cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart    : cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint    : cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine     : cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo     : cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc     : cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack     : cubic-bezier(0.600, -0.280, 0.735, 0.045);

$easeOutQuad    : cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic   : cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart   : cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint   : cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine    : cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo    : cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc    : cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack    : cubic-bezier(0.175, 0.885, 0.320, 1.275);

$easeInOutQuad  : cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart : cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint : cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine  : cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo  : cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack  : cubic-bezier(0.680, -0.550, 0.265, 1.550);