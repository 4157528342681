@import "../var";
@media screen and (max-width: $breakpoint-mobile) {
    .container .section_1440 {
        padding: 0;
    }
}
.section {
    @media screen and (max-width: $breakpoint-tablet) {
        margin-bottom:72px;
    }

}
.section_kv .kv_wrap {
    @media screen and (max-width: $breakpoint-tablet) {
        padding: 80px 0;
    }
}
.tab_area {
    width:100%;
    overflow:hidden;
    &.center {
        ul {
            @media screen and (max-width: $breakpoint-mobile) {
                justify-content:center;
            }
        }
    }
    ul {
        position: relative;
        z-index: 1;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display:none;
        }
    }
}
.btn_wrap {
    margin-top: 80px;
    @media screen and (max-width: $breakpoint-tablet) {
        margin-top: 32px;
    }
    text-align: center;
    a, button {
        display: inline-block;
        box-sizing: border-box;
        min-width: 200px;
        height: __to_em(64px, $font-size-base);
        padding: 0 __to_em(58px, $font-size-base);
        text-align: center;
        line-height: __to_em(64px, $font-size-base);
        border: $border-color solid 1px;
        border-radius: 8px;
        background-color: $color-white;
        font-size: __to_rem(18px, $font-size-base);
        font-weight: 700;

        &:hover {
            background-color: $color-primary;
            border-color: $color-primary;
            color: $color-white;
            transition: all 0.3s easeOutQuad;
        }
    }
}
.section_kv {
    + section {
        .section {
            margin-bottom:100px;
            @media screen and (max-width: $breakpoint-tablet) {
                margin-bottom:60px;
            }
        }
    }
}
.container-boxed .section {
    overflow: inherit; 
    iframe {
        border: none;
        background: transparent;
        min-width: 100%; max-height: none; min-height: 0;
        width: 1px; height: 1px;
    }
    .ifame_wrap {
        iframe {
            height:280px;
        }
    }
    .ifame_wrap2 {
        iframe {
            height:1034px;
            @media screen and (max-width: $breakpoint-mobile) {
                height:1134px;
            }
        }
    }
    .ifame_wrap3 {
        iframe {
            height:1538px;
            @media screen and (max-width: $breakpoint-mobile) {
                height:1464px;
            }
            
        }
    }
    .ifame_wrap4 {
        iframe {
            height:1204px;
            @media screen and (max-width: $breakpoint-mobile) {
                height:1194px;
            }
        }
    }
    &.tab {
        margin-bottom: 100px;
        @media screen and (max-width: $breakpoint-tablet) {
            margin-bottom: 60px;
        }
    }
}
.__grid {
    .col1 {
        flex: 1;
    }
    .col2 {
        flex: 2;
    }
}
.tab_area {
    ul {
        li {
            @media screen and (max-width: $breakpoint-tablet) {

                font-size:1.28em;
            }
            &:not(:last-child) {
                @media screen and (max-width: $breakpoint-tablet) {
                    margin-right:32px;
                }
            }
        }
    }
}
// .tab_area {
//     &.one_line {
//         ul {
//             justify-content: center;
//             &>li {
//                 margin: 0 __to_em(34px, $font-size-base);
//             }
//             @media screen and (max-width: $breakpoint-mobile) {
//                 &.__grid {
//                     flex-wrap: wrap;
//                     display: flex;
//                     gap: __to_rem(32px, $font-size-base);
//                     &>li {
//                         margin: 0 0;
//                         border-radius: 0;
//                         border: 0;
//                         a {
//                             padding: __to_rem(16px, $font-size-base) 0;
//                             font-size: 18px;
//                             text-align: center;
//                         }

//                         &.__active {
//                             color: $color-gray-2;
//                         }

//                     }
//                 }
//             }
//         }
//     }
// }
.link_btm {
    margin-top: 160px;
    @media screen and (max-width: $breakpoint-tablet) {
        margin-top: 80px;
    }
    .__grid {
        flex-wrap: wrap;
        justify-content: center;
        .__grid-item {
            width: calc(25% - __to_rem(12px, $font-size-base)); height: 110px;
        }
    }
    @media screen and (max-width: $breakpoint-desktop) {
        .__grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            .__grid-item {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: $breakpoint-mobile) {
        .__grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            .__grid-item {
                width: 100%;
            }
        }
    }
    button, a {
        position: relative;
        display: block;
        box-sizing: border-box;
        width: 100%; height: 110px;
        border: $border-color solid 1px;
        border-radius: __to_rem(8px, $font-size-base);
        padding: __to_rem(45px, $font-size-base)  __to_rem(32px, $font-size-base);
        color: $color-gray-2;
        font-size: 18px;
        font-weight: 700;
        @media screen and (max-width: $breakpoint-desktop) {
            padding:44px __to_rem(32px, $font-size-base);
        }
        @media screen and (max-width: $breakpoint-tablet) {
            font-size:16px;
        }
        &::before {
            content: '';
            display: block;
            width: 56px; height: 56px;
            position: absolute;
            top: 50%; right: 32px; transform:translateY(-50%);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            @media screen and (max-width: $breakpoint-tablet) {
                width:48px;
                height:48px;
            }
        }
        &.info::before {
            background-image: url(../../img/icon/linkbtm_01.svg);
            @media screen and (max-width: $breakpoint-tablet) {
                background-image: url(../../img/icon/linkbtm_01_mo.svg);
            }
        }
        &.cate::before {
            background-image: url(../../img/icon/linkbtm_02.svg);
            @media screen and (max-width: $breakpoint-tablet) {
                background-image: url(../../img/icon/linkbtm_02_mo.svg);
            }
        }
        &.esg::before {
            background-image: url(../../img/icon/linkbtm_03.svg);
            @media screen and (max-width: $breakpoint-tablet) {
                background-image: url(../../img/icon/linkbtm_03_mo.svg);
            }
        }
        &.news::before {
            background-image: url(../../img/icon/linkbtm_04.svg);
            @media screen and (max-width: $breakpoint-tablet) {
                background-image: url(../../img/icon/linkbtm_04_mo.svg);
            }
        }
        &.res::before {
            background-image: url(../../img/icon/linkbtm_05.svg);
            @media screen and (max-width: $breakpoint-tablet) {
                background-image: url(../../img/icon/linkbtm_05_mo.svg);
            }
        }
        &.ir::before {
            background-image: url(../../img/icon/investor1.svg);
            @media screen and (max-width: $breakpoint-tablet) {
                background-image: url(../../img/icon/investor1_mo.svg);
            }
        }
        &.cntus::before {
            background-image: url(../../img/icon/linkbtm_06.svg);
            @media screen and (max-width: $breakpoint-tablet) {
                background-image: url(../../img/icon/linkbtm_06_mo.svg);
            }
        }
        
        span {
            vertical-align: middle;
        }
        &::after {
            content: '';
            display: inline-block;
            width: 16px; height: 16px;
            margin-left: __to_rem(6px, $font-size-base);;
            background-image: url(../../img/icon/arrow-right-bk.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px auto;
            vertical-align: middle;
        }
        &:hover, &.__active {
            color: $color-primary;
            border: $color-primary solid 1px;
            &::after {
                background-image: url(../../img/icon/arrow-right-bl.svg);
            }
        }
    }
}
.tbl_wap {
    table-layout: fixed;
    overflow-x: auto; overflow-y: hidden;
    table {
        min-width: 100%;
        width: 100%;
        border-top: $color-gray-2 solid 2px;
        @media screen and (max-width: $breakpoint-tablet) {
            width: auto;
        }
        th, td {
            border-bottom: $border-color solid 1px;
            border-left: $border-color solid 1px;
            vertical-align: middle;
            text-align: center;
            word-break: keep-all;
            font-size: __to_em(18px, $font-size-base);
            line-height: __to_em(32px, $font-size-base);
            letter-spacing:-.063em;
            &:first-child {
                border-left: none;
                border-right: solid 1px $border-color;
            }
            &.tl {
                text-align: left;
            }
            &.bl {
                border-left: $border-color solid 1px;
            }
        }
        th {
            padding: __to_em(21px, $font-size-base) __to_em(19px, $font-size-base);
            font-weight: 700;
            span {
                @media screen and (max-width: $breakpoint-tablet) {
                    display:none;
                }
            }
        }
        td {
            padding: __to_em(29px, $font-size-base) __to_em(19px, $font-size-base);
        }
    }

    &.t2 {
        table th, table, td {
            border-left: none;
            text-align: left;
            padding-left: __to_em(29px, $font-size-base);
            padding-right: __to_em(29px, $font-size-base);
        }
        th:first-child,td:first-child {
            border-right: 0;
        }
    }

    &.t3 {
        tr>td:first-child {
            text-align: left;
        }
    }
}
.tbl_exp {
    margin-top: __to_rem(24px, $font-size-base);
    color: $color-gray-8;
    font-size: __to_em(15px, $font-size-base);

    + .tbl_exp {
        margin-top:8px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
        float: none;
    }

    &.al_r {
        text-align: right;
    }
}
.tbl_header + .tbl_wap {
    margin-top: __to_rem(32px, $font-size-base);
}
.select_box {
    position: relative;
    width: 100%; height: 56px;
    font-size: 15px;
    @media screen and (max-width: $breakpoint-tablet) {
        height: 48px;
        font-size: 14px;
    }
    .selected {
        width: 100%; height: 100%;
        box-sizing: border-box;
        border: $border-color solid 1px;
        padding: 0 2em 0 1em;
        border-radius: 8px;
        background: $color-white url(../../img/icon/arrow-down-bk.svg) no-repeat right 0.9em top 50%;
        display: flex;
        align-items: center;
        &.active {
            background: $color-white url(../../img/icon/arrow-up-bk.svg) no-repeat right 0.9em top 50%;
        }
    }

    .select_list {
        display: none;
        width: 100%;
        position: absolute;
        box-sizing: border-box;
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
        z-index: 1;
        border-radius: 8px;
        background-color: $color-white;
        border: 1px solid $color-white;
        top: 57px; left: 0;
        padding: __to_rem(16px, $font-size-base) 0;
        &.active {
            display: block;
        }
        button {
            width: 100%; height: 40px;
            padding: 0 1em;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            font-size: inherit;
            text-align: left;

            &:hover, &.active {
                color: $color-primary;
                background-color: #f5f5f5;
            }
        }

        @media screen and (max-width: $breakpoint-tablet) {
            top: 49px;
        }
    }

    &.mid {
        height: 48px;
        width: 265px;

        @media screen and (max-width: $breakpoint-tablet) {
            height: 40px;
            width: 135px;

        }
        .select_list {
            top: 49px;
            @media screen and (max-width: $breakpoint-tablet) {
                top: 41px;
            }
        }
    }

}
.downbox {
    margin-top: __to_rem(27px, $font-size-base);
    .__grid {
        flex-wrap: wrap;
        .__grid-item {
            width: calc(25% - __to_rem(12px, $font-size-base)); height: 360px;
        }
    }
    @media screen and (max-width: $breakpoint-tablet) {
        .__grid {
            .__grid-item {
                height: 268px;
            }
        }
    }
    @media screen and (max-width: $breakpoint-mobile) {
        .__grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            .__grid-item {
                width: 100%; height: 190px;
            }
        }
    }
    button, a:not(.er-download-btn),
    .er-box {
        background: #F5F5F5;
        border-radius: __to_rem(8px, $font-size-base);
        box-sizing: border-box;
        width: 100%; height: 100%;
        padding: 3.1111rem;
        transition: all 300ms ease;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-position: top 50% right 56px;
        background-repeat: no-repeat;
        position: relative;
        .tit {
            font-size: 27px;
            font-weight: 700;
            line-height:36px;
            @media screen and (max-width: $breakpoint-tablet) {
                font-size:18px;
                line-height:28px;
            }
        }
        i {
            display: block;
            font-weight: 700;
            white-space: nowrap;
            span {
                vertical-align: middle;
                font-size: __to_em(18px, $font-size-base);
                @media screen and (max-width: $breakpoint-tablet) {
                    font-size:14px;
                }
            }
            &::after {
                content: '';
                display: inline-block;
                width: __to_rem(40px, $font-size-base); height:  __to_rem(40px, $font-size-base);
                margin-left: __to_rem(12px, $font-size-base);
                background-image: url(../../img/icon/download.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                vertical-align: middle;
            }
        }

        &:hover, &.active {
            background-color: $color-primary;
            color: $color-white;
        }
        @media screen and (max-width: $breakpoint-tablet) {
            padding: 32px;
        }
        @media screen and (max-width: $breakpoint-mobile) {
            padding: 24px;
            i {
                span {
                    display: none;
                }
                &::after {
                    margin-left: 0;
                }
            }
        }
        //.er-box 여러 버튼 있을 경우 분기
        &.has-btns {
            .er-btn-wrap {
                display: flex;
                flex-direction: column;
                row-gap: 0.6666rem;

                @media screen and (max-width: $breakpoint-mobile) {
                    flex-direction: row;
                    column-gap: 0.7142rem;
                }
                
                a {
                    display: block;
                    width: fit-content;
                }
                a.sound {
                    i::after {
                        background-image: url(../../img/icon/download_audio.svg);
                    }
                }
                a.sub {
                    i::after {
                        background-image: url(../../img/icon/download_cc.svg);
                    }
                }
            }
        }
    }
    .er-box {
        @media screen and (min-width: $breakpoint-tablet) {
            padding: 2.2222rem !important;
        }
    }
    &.t2 {
        button, a {
            i {
                span {
                    display: inline-block;
                }
                &::after {
                    content: '';
                    display: inline-block;
                    width: __to_rem(14px, $font-size-base); height: __to_rem(14px, $font-size-base);
                    margin-left: __to_rem(6px, $font-size-base);
                    background-image: url(../../img/icon/arrow-right-bk.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    vertical-align: middle;
                }
            }
            &:hover, &.active {
                i {
                    &::after {
                        background-image: url(../../img/icon/arrow-right.svg);
                    }
                }
            }
        }
    }
}
.linkbox {
    padding-top: 80px;
    border-top: $border-color solid 1px;
    .__grid {
        flex-wrap: wrap;
        .__grid-item {
            width: calc(50% - __to_rem(12px, $font-size-base)); height: 230px;
        }
    }
    @media screen and (max-width: $breakpoint-tablet) {
        padding-top: 48px;
        .__grid {
            .__grid-item {
                height: 144px;
            }
        }
    }
    @media screen and (max-width: $breakpoint-mobile) {
        .__grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            .__grid-item {
                width: 100%;
            }
        }
    }
    button, a {
        background: #F5F5F5;
        border-radius: __to_rem(8px, $font-size-base);
        box-sizing: border-box;
        width: 100%; height: 100%;
        padding: __to_rem(56px, $font-size-base) __to_rem(56px, $font-size-base);
        transition: all 300ms ease;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        background-position: top 50% right 56px;
        background-repeat: no-repeat;
        @media screen and (max-width: $breakpoint-tablet) {
            padding:2.2857rem 2.2857rem;
        }
        @media screen and (max-width: $breakpoint-mobile) {
            padding:2.2857rem 1.7142rem
        }
        strong {
            display: block;
            color: $color-white;
            font-size: __to_rem(27px, $font-size-base);
            @media screen and (max-width: $breakpoint-tablet) {
                font-size:18px;
            }
            &::after {
                content: '';
                display: inline-block;
                width: 16px; height: 16px;
                margin-left: __to_rem(6px, $font-size-base);;
                background-image: url(../../img/icon/arrow-right.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 16px auto;
                vertical-align: middle;
            }
        }

        &.b1 {
            background-color: $color-secondary2;
            background-image: url(../../img/icon/linkbox_01.svg);
            background-position: right 56px bottom 56px;
            @media screen and (max-width: $breakpoint-tablet) {
                background-size: 48px 48px;
                background-position: right 32px bottom 32px;
            }
        }
        &.b2 {
            background-color: $color-secondary1;
            background-image: url(../../img/icon/linkbox_02.svg);
            background-position: right 56px bottom 56px;
            @media screen and (max-width: $breakpoint-tablet) {
                background-size: 48px 48px;
                background-position: right 32px bottom 32px;
            }
        }
    }
}
.linkbox2 {
    @media screen and (max-width: $breakpoint-mobile) {
        .__grid {
            flex-wrap: wrap;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }
    + .overview_link {
        padding-bottom:12px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
        .__headline6 {
            font-size: 18px;
        }
    }
    .col2.graybox {
        flex-basis: 7em;
    }
    .__grid-item {
        flex: 1;
        height: 320px;
        @media screen and (max-width: $breakpoint-tablet) {
            height: 180px;
        }
        @media screen and (max-width: $breakpoint-mobile) {
            height: 160px;
        }
        button, a {
            background: #F5F5F5;
            border-radius: __to_rem(8px, $font-size-base);
            box-sizing: border-box;
            width: 100%; height: 100%;
            padding: __to_rem(56px, $font-size-base) __to_rem(56px, $font-size-base);
            transition: all 300ms ease;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-position: bottom 56px right 56px;
            background-repeat: no-repeat;
            background-size: 64px 64px;

            strong {
                display: block;
                position:relative;
                color: $color-white;
                text-align:left;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 16px; height: 16px;
                    margin-left: __to_rem(6px, $font-size-base);
                    background-image: url(../../img/icon/arrow-right.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 16px auto;
                    vertical-align:middle;
                }
            }

            .v_icon {
                width:64px;
                height:64px;
                display: block;
                align-self: flex-end;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                @media screen and (max-width: $breakpoint-tablet) {
                    width:3.4285em;
                    height:3.4285em;
                }
            }

            &.b1 {
                background-color: $color-primary;
                .v_icon {
                    background-image: url(../../img/icon/linkbox2_01.svg);
                }
            }
            &.b2 {
                background-color: $color-secondary2;
                .v_icon {
                    background-image: url(../../img/icon/linkbox2_02.svg);
                }
            }
            &.b3 {
                background-color: $color-secondary1;
                .v_icon {
                    background-image: url(../../img/icon/linkbox2_03.svg);
                }
            }
            @media screen and (max-width: $breakpoint-tablet) {
                padding: 40px 32px;
            }
            @media screen and (max-width: $breakpoint-mobile) {
                padding: 32px 24px;
            }
        }
    }
}

.linkbox3 {
    padding-top: __to_em(80px, $font-size-base);
    @media screen and (max-width: $breakpoint-tablet) {
        .__headline6 {
            font-size: 18px;
        }
    }
    @media screen and (max-width: $breakpoint-mobile) {
        .__grid {
            flex-wrap: wrap;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .__grid-item {
        flex: 1;
        height: 360px;
        @media screen and (max-width: $breakpoint-tablet) {
            height: 180px;
        }
        @media screen and (max-width: $breakpoint-mobile) {
            height: 160px;
        }
        .ir_box {
            background: #F5F5F5;
            border-radius: __to_rem(8px, $font-size-base);
            box-sizing: border-box;
            width: 100%; height: 100%;
            padding: __to_rem(56px, $font-size-base) __to_rem(56px, $font-size-base);
            transition: all 300ms ease;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-position: bottom 56px right 56px;
            background-repeat: no-repeat;

            strong {
                display: block;
                color: $color-white;
                &.arr::after {
                    content: '';
                    display: inline-block;
                    width: 16px; height: 16px;
                    margin-left: __to_rem(6px, $font-size-base);
                    background-image: url(../../img/icon/arrow-right.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 16px auto;
                    vertical-align: middle;
                }
            }
            .__icon {
                max-height: __to_rem(64px, $font-size-base);
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                img {
                    display: inline-block;
                    height: 100%;
                    object-fit: contain;
                }
            }
            &.b1 {
                background-color: $color-primary;
            }
            &.b2 {
                background-color: $color-secondary2;
            }
            &.b3 {
                background-color: $color-secondary1;
            }
            @media screen and (max-width: $breakpoint-tablet) {
                padding: 40px 32px;
            }
            @media screen and (max-width: $breakpoint-mobile) {
                padding: 32px 24px;
            }
        }
    }
}

.ir_header {
    em {
        color: $color-primary;
    }

    br {
        display: none;
        @media screen and (max-width: $breakpoint-mobile) {
            display: block;
        }
    }
}

.graybox {
    background-color: #F5F5F5;
    border-radius: 8px;
    padding: __to_rem(56px, $font-size-base);

    &.iframe_box {
        //padding-right: 0;
    }

    .ifame_wrap {
        height: auto;

        iframe {
            //height: 412px !important;
        }
    }


    @media screen and (max-width: $breakpoint-tablet) {
        padding: __to_rem(40px, $font-size-base) __to_rem(32px, $font-size-base);

        .ifame_wrap {
            height: 306px;
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        padding: __to_rem(32px, $font-size-base) __to_rem(24px, $font-size-base);

        .ifame_wrap {
            height: 362px;
        }
    }
}

.ifame_wrap {
    border-radius: 8px;
    height: 480px;
    overflow: hidden;
    @media screen and (max-width: $breakpoint-tablet) {
        height: 306px;
    }
    @media screen and (max-width: 455px) {
        height: 446px;
    }
}
.btn_download {
    display: inline-block;
    min-width:  190px;
    box-sizing: border-box;
    border: $border-color solid 1px;
    background-color: $color-white;
    height: __to_rem(48px, $font-size-base);
    line-height: __to_rem(48px, $font-size-base);
    text-align: center;
    border-radius: 8px;
    font-size: __to_em(15px, $font-size-base);
    span {
        // vertical-align: middle;
    }
    &::after {
        content:'';
        display: inline-block;
        width: 32px; height: 32px;
        margin-left: 12px;
        background: url(../../img/icon/download.svg) no-repeat 50% 50%;
        background-size: cover;
        vertical-align: middle;
    }
    @media screen and (max-width: $breakpoint-tablet) {
        min-width:  160px;
    }
}

.tbl_wap.justice {
    table {
        td.bd_l {
            border-left:1px solid #e5e5e5;
        }
        td.txt {
            text-align:left;
        }
    }
}

.section_kv .kv_wrap .__breadcrumb {
    @media screen and (max-width: $breakpoint-tablet) {
        display:none;
    }
}

.graybox .inner {
    @media screen and (max-width: $breakpoint-desktop) {
        //padding:40px 32px;
    }
    @media screen and (max-width: $breakpoint-mobile) {
        //padding:32px 24px;
    }

    // iframe의 폰트 사이즈에 맞춰 강제 조정
    .font-size-iframe-tit {
        font-size: 32px;

        @media screen and (max-width: 1355px) {
            font-size: 18px;
        }
        @media screen and (max-width: $breakpoint-mobile) {
            font-size: 32px !important;
        }
        @media screen and (max-width: 714px) {
            font-size: 18px !important;
        }
    }
    .font-size-iframe-tit-sub {
        font-size: 18px;

        @media screen and (max-width: 1355px) {
            font-size: 14px;
        }
        @media screen and (max-width: $breakpoint-mobile) {
            font-size: 18px;
        }
        @media screen and (max-width: 714px) {
            font-size: 14px !important;
        }
        @media screen and (max-width: 444px) {
            font-size: 10px !important;
        }
    }
}

.report_box {
    margin-top:75px;
    
    @media screen and (max-width: $breakpoint-tablet) {
        margin-top:32px;
    }
    .report_item {
        position:relative;
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        padding:38px 32px;
        box-sizing: border-box;
        @media screen and (max-width: $breakpoint-desktop) {
            padding:21px 24px;
        }
        @media screen and (max-width: $breakpoint-tablet) {

        }
        &:last-child {
            margin-top:24px;
        }
        .tit {
            color:#222;
            font-weight:700;
            line-height:22px;
        }
        i {
            &::after {
                content: "";
                position:absolute;
                top:50%;
                right:32px;
                transform:translateY(-50%);
                display: inline-block;
                width: 40px;
                height: 40px;
                margin-left: 0.6666666667rem;
                background-image: url(../../img/icon/download.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                vertical-align: middle;
                @media screen and (max-width: $breakpoint-desktop) {
                    width:28px;
                    height:28px;
                    right:24px;
                }
            }
        }
    }
}

.overview_link {
    padding-top:16px;
    @media screen and (max-width: $breakpoint-tablet) {
        padding-top:10px;
    }
}

.policy_area {
    margin-top: __to_rem(80px, $font-size-base);
    @media screen and (max-width: $breakpoint-tablet) {
        margin-top:48px;
    }
    .policy_item {
        + .policy_item {
            margin-top:80px;
            @media screen and (max-width: $breakpoint-tablet) {
                margin-top:48px;
            }
        }
        p {
            @include __txt_to_rem(24px, 30px, $font-size-base);
            font-family: $font-family;
            font-weight: 700;
            //font-size:24px;
            //font-weight:700;
            color:#222;
            @media screen and (max-width: $breakpoint-tablet) {
                //font-size:16px;
                //line-height:21px;
                @include __txt_to_rem(16px, 21px, $font-size-min);
                font-family: $font-family;
            }
            span {
                color:#1428a0;
            }
        }
        ul {
            margin-top:28px;
            padding-left:__to_rem(25px, $font-size-base);
            @media screen and (max-width: $breakpoint-tablet) {
                margin-top:24px;
                padding-left:__to_rem(7px, $font-size-min);
            }
            li {
                @include __txt_to_rem(18px, 32px, $font-size-base);
                font-family: $font-family;
                font-weight: 400;
                //font-size:18px;
                //line-height:32px;
                color:#222;
                text-indent: -11px;

                @media screen and (max-width: $breakpoint-tablet) {
                    //font-size:14px;
                    //line-height:22px;
                    @include __txt_to_rem(14px, 22px, $font-size-min);
                    font-family: $font-family;
                    font-weight: 400;
                }
                span {
                    @media screen and (max-width: $breakpoint-tablet) {
                        display:inline;
                    }
                }
                //&.txt-indent-custom{
                //    display:flex;
                //    justify-content: flex-start;
                //    align-items: baseline;
                //}
            }
        }
        
    }
    &.mg-top-0{
        margin-top:0;
        .policy_item{
            ul{
                padding-left:0;
            }
        }
    }
    .download_area {
        width: fit-content;
        margin-top: __to_rem(24px, $font-size-base);
        padding-left:__to_rem(25px, $font-size-base);

        @media screen and (max-width: $breakpoint-tablet) {
            margin-top:24px;
            padding-left:__to_rem(7px, $font-size-min);
        }

        @media screen and (max-width: $breakpoint-tablet) {
            margin-top: __to_rem(24px, $font-size-min);
        }
        .download_btn {
            display: flex;
            align-items: center;
            $font-size: 15px;
            font-size: $font-size;
            padding: __to_em(20px, $font-size) __to_em(32px, $font-size);
            border: 1px solid $color-primary;
            border-radius: 3em;
            transition: background 300ms ease;

            font-size: __to_rem(15px, $font-size-base);
            font-weight: 700;
            line-height: __to_rem(21px, $font-size-base);
            color: $color-primary;

            @media screen and (max-width: $breakpoint-tablet) {
                padding: __to_em(15px, $font-size-min) __to_em(28px, $font-size-min);
            }
            @media screen and (min-width: $breakpoint-tablet) {
                &:hover{
                    background: $color-primary;
                    color: #fff;

                    i {
                        &::after {
                            background-image: url(../../img/icon/download_none_bg_white.svg);
                        }
                    }
                }
            }
            p {
                display: inline-block;
                font-size: __to_rem(15px, $font-size-base);
                font-weight: 700;
                line-height: 0;
                color: $color-primary;

                @media screen and (max-width: $breakpoint-tablet) {
                    font-size: __to_rem(14px, $font-size-min);
                }
            }
            i {
                display: block;
                font-weight: 700;
                white-space: nowrap;

                &::after {
                    content: '';
                    display: inline-block;
                    width: __to_rem(16px, $font-size-base); height:  __to_rem(16px, $font-size-base);
                    margin-left: __to_em(8px, $font-size);
                    background-image: url(../../img/icon/download_none_bg.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    vertical-align: middle;

                    @media screen and (max-width: $breakpoint-tablet) {
                        margin-left: 0.57243em;
                        width: __to_rem(16px, $font-size-min); height:  __to_rem(16px, $font-size-min);
                    }
                }
            }
        }
    }
}

.tbl_wap {
    + .clear {
        .line {
            &-left{
                float:left;
                @media screen and (max-width: $breakpoint-tablet) {
                    float:none;
                    margin-top: __to_rem(6px, $font-size-base);
                    >.tbl_exp{
                        margin-top:0;
                    }
                }
            }
            &-right{
                float:right;
                @media screen and (max-width:$breakpoint-tablet) {
                    float:none;
                }
            }
        }
    }
}

.graph_area {
    border-top:2px solid #222;
}

.graph_area {
    position:relative;
    border-top:2px solid #222;
    p {
        // position:absolute;
        // top:24px;
        // left:0;
        width:100%;
        padding-top:24px;
        font-size:18px;
        color:$color-primary;
        @media screen and (max-width: $breakpoint-tablet) {
            // position: static;
            // margin-top:16px;
            padding-top:16px;
            font-size:14px;
        }
    }
    img {
        width:100%;
    }
    .pc_img {
        padding-top:80px;
        @media screen and (max-width: $breakpoint-tablet) {
            display:none;
        }
    }
    .mo_img {
        display:none;
        @media screen and (max-width: $breakpoint-tablet) {
            display:block;
            // margin-top:54px;
            padding-top:54px;
        }
    }
}

.activity_list {
    margin-top:88px;
    overflow:hidden;
    @media screen and (max-width: $breakpoint-tablet) {
        margin-top:72px;
    }
    .activity_item {
        + .activity_item {
            margin-top: __to_rem(120px, $font-size-base);
            @media screen and (max-width: $breakpoint-tablet) {
                margin-top:72px;
            }
        }
        .active_header {
            display:flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top:80px;
            @media screen and (max-width: $breakpoint-tablet) {
                margin-top:48px;
            }

            h4 {
                font-size:27px;
                color:#222;
                font-weight:700;
                @media screen and (max-width: $breakpoint-tablet) {
                    font-size:18px;
                }
            }
        }
        .act_sub {
            .act_body {
                padding-top: __to_rem(28px, $font-size-base);
            }
            &:not(:first-child) {
                padding-top: __to_rem(80px, $font-size-base);
            }
        }

        .tbl_wap {
            margin-top:32px;
            @media screen and (max-width: $breakpoint-tablet) {
                margin-top:20px;
            }
            &.year {
                td {
                    &.tl {
                        text-align:left;
                    }
                }
            }
            &.tbl_multi {
                display: none;
                &.__active {
                    display: block;
                }
                caption {
                    display: none;
                }
            }
        }
        .desc {
            margin-top:56px;
            @media screen and (max-width: $breakpoint-tablet) {
                margin-top:32px;
            }
            &.dir_tit {
                margin-top:32px;
                @media screen and (max-width: $breakpoint-tablet) {
                    margin-top:20px;
                }
            }
            + .desc {
                margin-top:0;
            }
        }
        .desc-2 {
            margin-top: __to_em(28px, $font-size-base);
        }
        .item_wrap {
            display:flex;
            gap:16px;
            @media screen and (max-width: $breakpoint-tablet) {
                gap:10px;
            }
        }
        .item_notice {
            width:100%;
            box-sizing: border-box;
            background:#f5f5f5;
            padding:__to_rem(40px,$font-size-base);
            margin-top: __to_em(28px, $font-size-base);
            border-radius:8px;
            @media screen and (max-width: $breakpoint-tablet) {
                padding:24px;
            }
            ul {
                + strong {
                    margin-top:48px;
                }
                li {
                    position:relative;
                    @include __txt_to_rem(18px, 32px, $font-size-base);
                    color:#222;
                    line-height:__to_rem(32px, $font-size-base);
                    padding-left:16px;
                    letter-spacing:-.07em;
                    @media screen and (max-width: $breakpoint-tablet) {
                        font-size:14px;
                        line-height:22px;
                    }
                    span {
                        display:block;
                    }
                    &::before {
                        content:'・';
                        display:block;
                        position:absolute;
                        top:0;
                        left:0;
                    }

                    .unb_list {
                        li {
                            font-size: __to_rem(15px, $font-size-base);
                            line-height: 1.3rem;
                            letter-spacing: 0;
                            color: $color-gray-6;
                            &::before {
                                content:'-';
                            }
                        }
                    }
                }
            }
            strong {
                display:block;
                @include __txt_to_rem(18px, 32px, $font-size-base);
                color:#222;
                font-weight:700;
                @media screen and (max-width: $breakpoint-tablet) {
                    font-size:14px;
                }
            }
            p {
                margin-top:16px;
                letter-spacing:-0.07em;
                @media screen and (max-width: $breakpoint-tablet) {
                    font-size:14px;
                    margin-top:12px;
                }
            }
            .notice_exp {
                p {
                    font-size: __to_rem(15px, $font-size-base);
                    color: $color-gray-6;
                    margin-top: __to_rem(20px, $font-size-base);
                }
            }
        }
        .item_name_wrap {
            padding-top: __to_rem(56px, $font-size-base);
            dl {
                display: flex;
                flex-direction: column;
                dt {
                    font-size: __to_rem(22px, $font-size-base);
                }
                dd {
                    gap: __to_rem(16px, $font-size-base);
                    padding-top: __to_rem(16px,$font-size-base);
                    display: flex;
                    align-items: center;

                    &.f_to_g {
                        @media screen and (max-width: $breakpoint-mobile) {
                            flex-wrap: wrap;
                            div {
                                flex: 20%;
                            }
                            >div:nth-child( n + 4) {
                                flex: 40%;
                            }
                        }
                    }
                    @mixin ie {
                        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            @content;
                        }
                    }
                    @include ie {
                        &> * + * {
                            margin-left: __to_rem(16px, $font-size-base);

                            @media screen and (max-width: $breakpoint-mobile) {
                                &:nth-child(4) {
                                    margin-left: 0;
                                }
                                &:nth-child(n + 4) {
                                    margin-top: __to_rem(16px, $font-size-base);
                                }
                            }
                        }
                    }
                }
                div {
                    flex: 1;
                    border-radius: __to_em(8px, $font-size-base);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: __to_em(120px, $font-size-base);
                    flex-direction: column;
                    box-sizing: border-box;
                    padding: __to_em(22px, $font-size-base);

                    &.n_f {
                        background-color: $color-primary;
                        height: __to_em(80px, $font-size-base);

                    }
                    &.n_1 {
                        background-color: #3a4fd1;
                    }
                    &.n_2 {
                        background-color: $color-secondary2;
                    }
                    &.n_3 {
                        background-color: $color-secondary1;
                    }
                    &.n_4 {
                        background-color: #76b8cb;
                    }
                    &.n_5 {
                        background-color: #4dabb6;
                    }

                    @media screen and (max-width: $breakpoint-mobile) {
                        height: __to_em(100px, $font-size-base);
                        &.n_f{
                            height: __to_em(56px, $font-size-base);
                        }
                    }
                }
                &.p_07 {
                    @media screen and (max-width: $breakpoint-mobile) {
                        div {padding: 0.7em;}
                    }
                }
                &.p_08 {
                    @media screen and (max-width: $breakpoint-mobile) {
                        div {padding: 0.8em;}
                    }
                }
                &.p_15 {
                    @media screen and (max-width: $breakpoint-mobile) {
                        div {padding: 1.5em;}
                    }
                }
                &.p_17 {
                    @media screen and (max-width: $breakpoint-mobile) {
                        div {padding: 1.7em;}
                    }
                }
            }
            span {
                color: $color-white;
                font-weight: 700;
                text-align: center;

            }
            small {
                color: $color-white;
                padding-top: 6px;
                font-size: __to_rem(15px, $font-size-base);
                line-height: 1.2rem;
                word-break: keep-all;
                text-align: center;

            }


        }
        .download_area {
            float: right;
            margin-top: __to_rem(24px, $font-size-base);

            @media screen and (max-width: $breakpoint-tablet) {
                margin-top: __to_rem(24px, $font-size-min);
            }
            .download_btn {
                display: flex;
                align-items: center;
                $font-size: 15px;
                font-size: $font-size;
                padding: __to_em(20px, $font-size) __to_em(32px, $font-size);
                color: $color-primary;
                border: 1px solid $color-primary;
                border-radius: 3em;
                transition: background 300ms ease;

                @media screen and (max-width: $breakpoint-tablet) {
                    padding: __to_em(15px, $font-size-min) __to_em(28px, $font-size-min);
                }
                @media screen and (min-width: $breakpoint-tablet) {
                    &:hover{
                        background: $color-primary;
                        color: #fff;
                        i {
                            &::after {
                                background-image: url(../../img/icon/download_none_bg_white.svg);
                            }
                        }
                    }
                }
                p {
                    display: inline-block;
                    font-size: __to_rem(15px, $font-size-base);
                    font-weight: 700;
                    line-height: 0;

                    @media screen and (max-width: $breakpoint-tablet) {
                        font-size: __to_rem(14px, $font-size-min);
                    }
                }
                i {
                    display: block;
                    font-weight: 700;
                    white-space: nowrap;

                    &::after {
                        content: '';
                        display: inline-block;
                        width: __to_rem(16px, $font-size-base); height:  __to_rem(16px, $font-size-base);
                        margin-left: __to_em(8px, $font-size);
                        background-image: url(../../img/icon/download_none_bg.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        vertical-align: middle;

                        @media screen and (max-width: $breakpoint-tablet) {
                            margin-left: 0.57243em;
                            width: __to_rem(16px, $font-size-min); height:  __to_rem(16px, $font-size-min);
                        }
                    }
                }
            }
        }
    }
    .activity_item.mt {
        margin-top: __to_rem(120px, $font-size-base);
        @media screen and (max-width: $breakpoint-tablet) {
            margin-top:72px;
        }
    }
    .__font_body_1 {
        
    }
}

.link_banner { //[23.05.02] public disclosure 배너 추가
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    $font-size: 18px;
    font-size: $font-size;
    padding: __to_em(40px, $font-size);
    border: 1px solid $color-primary;
    border-radius: 8px;
    background-color: $color-primary;
    color: $color-white;
    box-sizing: border-box;

    @media screen and (max-width: $breakpoint-tablet) {
        flex-direction: column;
        padding: __to_em(24px, $font-size-min);
    }

    p {
        display: inline-block;
        font-size: __to_rem(18px, $font-size-base);
        line-height: __to_rem(32px, $font-size-base);
        
        &.web {
            display: none;
        }
        &.mo {
            display: inline-block;
        }

        @media screen and (min-width: $breakpoint-tablet) {
            max-width: __to_rem(640px, $font-size);

            &.web {
                display: inline-block;
            }
            &.mo {
                display: none;
            }
        }
        @media screen and (max-width: $breakpoint-tablet) {
            margin-right: auto;
            font-size: __to_rem(14px, $font-size-min);
            padding-bottom: __to_em(40px, $font-size-min);
        }
    }
    .link_btn {
        display: flex;
        align-items: center;
        $font-size: 15px;
        font-size: __to_rem(15px, $font-size-base);
        line-height: 0;
        font-weight: 700;
        padding: __to_em(19px, $font-size) __to_em(32px, $font-size) __to_em(18px, $font-size);
        border: 1px solid $color-white;
        border-radius: __to_em(50px, $font-size);

        @media screen and (min-width: $breakpoint-tablet) {
            &:hover {
                background-color: $color-white;
                color: $color-primary;

                i {
                    &::after {
                        background-image: url(../../img/icon/outlink_box_hov.svg);
                    }
                }
            }
        }

        @media screen and (max-width: $breakpoint-tablet) {
            margin-left: auto;
            padding: __to_em(12px, $font-size) __to_em(24px, $font-size);
        }

        i {
            display: block;
            white-space: nowrap;
    
            &::after {
                content: '';
                display: inline-block;
                width: __to_rem(16px, $font-size-base);
                height: __to_rem(16px, $font-size-base);
                margin-left: __to_em(8px, $font-size);
                background-image: url(../../img/icon/outlink_box.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                vertical-align: middle;
    
                @media screen and (max-width: $breakpoint-tablet) {
                    margin-left: 0.57243em;
                    width: __to_rem(16px, $font-size-min); height:  __to_rem(16px, $font-size-min);
                }
            }
        }
    }
}

#main.governance { //[23.08.23] 지배구조 governance 탭 셀렉트박스 내에 스크롤 추가
    .select_list {
        &.active {
            max-height: __to_rem(180px, $font-size-base);
            overflow-y: scroll;

            @media screen and (max-width: $breakpoint-tablet) {
                max-height: __to_rem(250px, $font-size-base);
            }
        }
    }
}